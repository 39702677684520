import React from 'react'
import classNames from 'classnames'
import DefaultLayout from '../../layout/default'
import AutomationMenu from '../../components/_V2/AutomationMenu'
import SimpleFeatures from '../../components/_V2/SimpleFeatures'

import ModesFeatures from '../../pagesSections/avtomatizaciya-obshchepita/ModesFeatures'
import AvailableFeatures from '../../pagesSections/avtomatizaciya-obshchepita/AvailableFeatures'
import MenuFeatures from '../../pagesSections/avtomatizaciya-obshchepita/MenuFeatures'
import ReportFeatures from '../../pagesSections/avtomatizaciya-obshchepita/ReportFeatures'

import ProductPresentation from '../../pagesSections/avtomatizaciya-obshchepita/ProductPresentation'

import Presentation
  from '../../pagesSections/avtomatizaciya-obshchepita/ProductPresentation/assets/obshchepit-presentation.png'
import CtaForm from '../../pagesSections/index/CtaForm'

import featuresData from '../../pages-data/_V2/automatization/obshchepit/features'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/automation.module.scss'
import { getSeoText } from "../../pages-data/_V2/automatization/obshchepit/seotext";
import SeoText from "../../components/_V2/SeoText";

export default function PageAvtomatizaciyaObshchepita() {
  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'quick resto, автоматизация кофейни, ресторанный бизнес, программа учёта, склад, общепит',
    // },
    {
      name: 'description',
      content: 'Quick Resto – автоматизация общепита: ресторанов, кафе и других заведений. CRM, онлайн-касса, аналитика продаж и складской учет в облаке. Бесплатный пробный период 14 дней.',
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/automation/avtomatizaciya-obshchepita/',
    },
    {
      property: 'og:title',
      content: 'Автоматизация общепита — CRM система и программа учета для заведений общественного питания | Quick Resto',
    },
    {
      property: 'og:description',
      content: 'Quick Resto – автоматизация общепита: ресторанов, кафе и других заведений. CRM, онлайн-касса, аналитика продаж и складской учет в облаке. Бесплатный пробный период 14 дней.',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `https://quickresto.ru${Presentation}`,
    },
  ]

  return (
    <DefaultLayout
      title="Автоматизация общепита — CRM система и программа учета для заведений общественного питания | Quick Resto"
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  pageStyles.automationPage,
      )}
      linkCanonical="https://quickresto.ru/automation/avtomatizaciya-obshchepita/"
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={metaTags.find((tag) => tag.property === 'og:title').content}
      schemaDescription={metaTags.find((tag) => tag.property === 'og:description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
      schemaUrl={metaTags.find((tag) => tag.property === 'og:url').content}
    >
      <div
        className={classNames(
					  pageStyles.pageSection,
					  styles.automation__headline,
        )}
      >
        <ProductPresentation
          leftColumn={styles.automation__presentationLeftColumn}
          title={styles.automation__presentationTitle}
        />

        <AutomationMenu className={styles.automation__menu} />
      </div>

      <ModesFeatures className={pageStyles.pageSection} />

      <AvailableFeatures className={pageStyles.pageSection} />

      <MenuFeatures className={pageStyles.pageSection} />

      <ReportFeatures className={pageStyles.pageSection} />

      <SimpleFeatures
        data={featuresData}
        className={pageStyles.pageSection}
      />

      <CtaForm
        isFry={false}
        isWhiteBackground
        className={pageStyles.pageSection}
      />
      <SeoText
          className={pageStyles.pageSection}
          data={getSeoText()}
      />
    </DefaultLayout>
  )
}
