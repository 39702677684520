import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	desktopTitle: (
		<span>Автоматизация заведений общественного питания&nbsp;</span>
	),
	price: "от 1990 ₽/мес",
	subTitle:
		"Современный софт для общепита содержит все инструменты, чтобы гости приходили чаще и покупали больше: пуш-уведомления об акциях, мобильные приложения и сайт для гостей, программы лояльности и бонусы. Quick Resto – готовое решение для автоматизации общепита от 1990 ₽/мес. ",
	figure: (
		<StaticImage
			className={styles.image}
			src="./assets/obshchepit-presentation.png"
			alt="автоматизация общепита"
			objectFit="contain"
			objectPosition="center"
			placeholder="blurred"
			quality={90}
		/>
	),
	cta: pagesLinks.registration.text,
	ctaLink: pagesLinks.registration,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования, \nс удобным интерфейсом. И две \nнедели бесплатно.",
}
