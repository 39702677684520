import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Универсальное решение",
			desktopTitle: "Электронное меню для заведений общественного питания",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Гостям не нужно ждать официанта, чтобы получить меню. Используя в
					общепите функционал электронного меню, в заведении всегда будут
					актуальные позиции и цены. Экономия на бумажной версии. Без затрат на
					разработку и обслуживание сайта.
				</p>
			),
			detailLink: pagesLinks.wlShop,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/obshchepit-menu-1.png"
					alt="электронное меню для общепита"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "QR меню",
			desktopTitle: "Размести ссылку на QR-меню в любом месте",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Офлайн: на столе, за барной стойкой, на входе в зал. Онлайн: на сайте,
					в соцсетях, на картах города.
				</p>
			),
			detailLink: pagesLinks.wlShop,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/obshchepit-menu-2.png"
					alt="QR-меню для общепита"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Синхронизация",
			desktopTitle: "Два клика — и новая скидка или акция уже в меню",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Сезонные позиции, новинки, стоп-листы, акции — гостю всегда доступна
					актуальная карта блюд. Если нужно изменить цену или добавить блюдо в
					меню, просто обнови данные в бэк-офисе.
				</p>
			),
			detailLink: pagesLinks.wlShop,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="./assets/obshchepit-menu-3.png"
					alt="онлайн меню для общепита"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
