import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import classNames from "classnames";

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Скидки и бонусы",
			desktopTitle: "Удобная CRM-система управления",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Карты лояльности — современный инструмент для вовлечения гостей в
					акции, скидки и бонусные программы. Электронные карты не потеряются и
					будут всегда под рукой.
				</p>
			),
			detailLink: pagesLinks.passkit,
			mediaContent: (
				<>
					<StaticImage
						className={mediaContent.image1}
						src="./assets/obshchepit-available-1.png"
						alt="электронные карты лояльности"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={classNames(
							mediaContent.image1,
							mediaContent.image1Mobile
						)}
						src="./assets/obshchepit-available-1-mobile.png"
						alt="электронные карты лояльности"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</>

			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Лояльность",
			desktopTitle: "Привлекай гостей",
			desktopContent:
				"Не нужно устанавливать приложение на телефон. Достаточно отсканировать QR-код, заполнить анкету и добавить карту лояльности в электронный кошелёк Apple Wallet или Google Pay. ",
			detailLink: pagesLinks.passkit,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/obshchepit-available-2.png"
					alt="полная автоматизация общепита"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Экосистема",
			desktopTitle: "Мгновенная интеграция с учётной системой",
			desktopContent:
				"Программа учёта Quick Resto автоматически начисляет приветственные бонусы новым гостям. Накопленные баллы видны сразу, а все данные клиентов синхронизируются с CRM-системой.",
			detailLink: pagesLinks.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="./assets/obshchepit-available-3.png"
					alt="crm система для общепита"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
