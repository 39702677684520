import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Отчёты",
			desktopTitle: "Онлайн-программа управления и учета для общепита",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Всё под рукой: анализ продаж по времени или периоду, конструктор
					кастомных отчетов, предустановленные отчеты и ABC-анализ. Можно
					настроить доступ с любого устройства.
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/obshchepit-report-1.png"
					alt="программа учета продуктов в общепите"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Управление",
			desktopTitle: "Оценивай рентабельность заведения",
			desktopContent:
				"Управляй меню, веди учёт склада, работай с персоналом, следи за аналитикой продаж, привлекай новых и удерживай старых клиентов.",
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/obshchepit-report-2.png"
					alt="софт для общепита"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Техкарты",
			desktopTitle: "Техкарты в два счёта",
			desktopContent:
				"Программа калькуляции для общепита поможет контролировать потери при обработке ингредиентов и стоимость закупок. Программа Quick Resto рассчитает фудкост, произведет калькуляцию себестоимости и списание со склада автоматически.",
			detailLink: pagesLinks.menu,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/obshchepit-report-3.png"
					alt="программа с технологическими картами для общепита"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
