import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Безопасность данных",
			desktopTitle: "Программа автоматизации общепита в облаке",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Вся информация о заведении безопасно хранится в облаке. Распределенная
					сеть серверов поддерживает сохранность данных.
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/obshchepit-modes-1.png"
					alt="программа для общепита"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Настройка",
			desktopTitle: "Быстрый запуск",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Для работы с системой автоматизации Quick Resto не нужно
					специализированное оборудование. Кассовый терминал на планшете
					подключается за несколько минут и не требует обслуживания. С простым
					интерфейсом разберётся любой сотрудник. Полная автоматизация общепита
					доступна уже сегодня.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/obshchepit-modes-2.png"
					alt="автоматизация общественного питания"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Продажи",
			desktopTitle: "Зарабатывай больше",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Составляй маржинальные комбо-наборы и бизнес ланчи. Вводи в меню
					топпинги и модификаторы блюд и увеличивай средний чек. Вести учет
					продуктов питания в программе для общепита гораздо проще.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="./assets/obshchepit-modes-3.png"
					alt="кассовая система для фастфуда"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
